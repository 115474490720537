import React from "react";

export default function Comp({ props, rectOptions, currentIndex }) {
  const { subRectOptions } = rectOptions;
  const { width, height, left, top } = subRectOptions[currentIndex];
  const { flyImageList } = props;
  // todu:这个1不是固定数值，如果要适配的话还需要转换数值
  console.log(flyImageList,'flyImageList1=======');
  // 确保flyImageList存在且flyImageList[0]也有imageUrl1属性
  const imageUrl =
    flyImageList && flyImageList.length > 0 && flyImageList[0].imageUrl1
      ? flyImageList[0].imageUrl1
      : "";

  return width < 1 && height < 1 ? (
    <></>
  ) : (
    <div
      style={{
        position: "absolute",
        width,
        height,
        overflow: "hidden",
        transform: `translate(${left}px, ${top}px)`,
      }}
    >
      <img
        src={imageUrl}
        alt=""
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
}
