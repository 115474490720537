import React, { useRef, useLayoutEffect, useImperativeHandle, useMemo } from 'react'
import _ from 'lodash'

export default React.forwardRef(function Preview({
    rectOptions,
    props,
    floorData,
    currentIndex = 0,
    track = () => 0,
}, ref) {
    const { subRectOptions } = rectOptions
    const { subProps, flyImageList } = props
    const allFlyWrapList = useRef([])
    const wrapTimerGrp = useRef([])
    const isEnterAnimate = useRef(false)
    const currentFlyImg = flyImageList[currentIndex]
    const hybridList = currentFlyImg ? [{
        width: subRectOptions[0].width,
        height: subRectOptions[0].height,
        top: subRectOptions[0].top,
        left: subRectOptions[0].left,
        flyDistance: currentFlyImg.flyDistance1,
        flyDirection: currentFlyImg.flyDirection1,
        flyTime: currentFlyImg.flyTime1,
        imageUrl: currentFlyImg.imageUrl1,
        hotArea: currentFlyImg.hotArea1
    }] : []

    const flyImageId = useMemo(() => {
        return 'tgb_fly-image-bottom_' + Date.now()
    }, [currentIndex])

    const initAnimateStyle = (hybridItem) => {
        let style = {}
        switch (hybridItem.flyDirection) {
            case '左':
                style = {
                    transform: `translateX(-${hybridItem.flyDistance}px)`,
                }
                break
            case '右':
                style = {
                    transform: `translateX(${hybridItem.flyDistance}px)`
                }
                break
            case '上':
                style = {
                    transform: `translateY(-${hybridItem.flyDistance}px)`
                }
                break
            case '下':
                style = {
                    transform: `translateY(${hybridItem.flyDistance}px)`
                }
                break
        }
        return style
    }

    useLayoutEffect(() => {
        if (hybridList.length <= 0) return
        allFlyWrapList.current = []
        wrapTimerGrp.current = []
        hybridList.forEach((item, index) => {
            allFlyWrapList.current.push(document.getElementById(`${flyImageId + '-' + index}`))
            wrapTimerGrp.current.push(null)
        })
        outView()
        setTimeout(() => {
            inView()
        }, 100)

    }, [currentIndex])

    useImperativeHandle(ref, () => ({
        inView,
        outView,
    }))

    const inView = () => {
        allFlyWrapList.current.forEach((elem, index) => {
            const item = hybridList[index]
            elem.style.transition = `${item.flyTime}s`
            elem.style.transform = 'translate(0, 0)'
        })
    }

    const outView = () => {
        isEnterAnimate.current = false
        allFlyWrapList.current.forEach((elem, index) => {
            const item = hybridList[index]
            elem.style.transition = '0ms'
            elem.style.transform = initAnimateStyle(item).transform
        })
        wrapTimerGrp.current.forEach(timer => clearInterval(timer))
    }

    return (
        <>
            {
                hybridList.length > 0 && hybridList.map((item, index) => {
                    return (
                        <div
                            key={index}
                            id={flyImageId + '-' + index}
                            style={Object.assign({
                                width: item.width,
                                height: item.height,
                                position: 'absolute',
                                left: item.left,
                                top: item.top,
                                zIndex: 0,
                            }, initAnimateStyle(item))}
                            onClick={() => {
                                handlePress(item.clickUrl, floorData)
                            }}
                        >
                            <img style={{
                                width: '100%',
                                height: '100%',
                                transition: `${item.animateRate}s`
                            }} src={item.imageUrl} alt="" />
                            {item.hotArea && item.hotArea.length > 0 && item.hotArea.map((hot, hotIndex) => {
                                return (
                                    <div
                                        key={hotIndex + 'hotIndex'}
                                        style={{
                                            width: hot.width,
                                            height: hot.height,
                                            position: 'absolute',
                                            left: hot.left,
                                            top: hot.top,
                                        }}
                                        onClick={() => {
                                            window.open(hot.jumpLink)
                                        }}
                                    >
                                        {
                                            hot.showPrice && (
                                                <div
                                                    style={{
                                                        color: hot.priceConfig.color,
                                                        fontSize: hot.priceConfig.fontSize,
                                                        fontWeight: hot.priceConfig.fontWeight,
                                                        position: 'absolute',
                                                        left: hot.priceConfig.left,
                                                        top: hot.priceConfig.top,
                                                    }}
                                                >{hot.priceConfig.showPrefiex ? '￥' : ''}{hot.priceConfig.useInputPrice ? hot.priceConfig.inputPrice : '100'}</div>
                                            )
                                        }

                                    </div>
                                )
                            })}
                        </div>
                    )

                })
            }
        </>
    )
})