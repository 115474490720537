import Background from './Background'
import FloatIcon from './FloatIcon'
import VideoComp from './VideoComp'
import NormalBanner from './NormalBanner'
import HotBanner from './HotBanner'
import AlertComp from './AlertComp'
import NavComp from './NavComp'
import LoopScroll from './LoopScroll'
import Scroll from './Scroll'
import CountDownBG from './CountDownBG'
import CountDown from './CountDown'
import ArrowComp from './ArrowComp'
import SlideComp from './SlideComp'
import Marquee from './Marquee'
import ExpandHide from './ExpandHide'
import AccordionBanner from './AccordionBanner'
import FlyImage from './FlyImage'
import FlipImage from './FlipImage'
import OpenDoorAlert from './OpenDoorAlert'
import Template from './Template'
import ScaleAlert from './ScaleAlert'
import NestBanner from './NestBanner'
import SingleNav from './SingleNav'
import TransparentNav from './TransparentNav'
import ScaleBanner from './ScaleBanner'
import HotBackground from './HotBackground'
import VegaScroll from './VegaScroll'
import ScaleLoopScroll from './ScaleLoopScroll'
import BottomFlyImage from './BottomFlyImage'
import TopFlyImage from './TopFlyImage'
import FlyImageHotArea from './FlyImageHotArea'
import ChangeBtn from './ChangeBtn'
import ShopInform from './ShopInform'
import ScrollVideo from './ScrollVideo'
import CubeBanner from './CubeBanner'
import ShadeImage from './ShadeImage'
import HotAccordion from './HotAccordion'
import GifImg from './GifImg'
import ImgBanner from './ImgBanner'
import SlideNavComp from './SlideNavComp'
import HotFadeBanner from './HotFadeBanner'
const plugins = {
    HotFadeBanner,
    SlideNavComp,
    ImgBanner,
    GifImg,
    HotAccordion,
    ShadeImage,
    CubeBanner,
    ScrollVideo,
    FlyImageHotArea,
    TopFlyImage,
    BottomFlyImage,
    ScaleLoopScroll,
    VegaScroll,
    HotBackground,
    ScaleBanner,
    TransparentNav,
    Template,
    Background,
    FloatIcon,
    VideoComp,
    HotBanner,
    NormalBanner,
    AlertComp,
    NavComp,
    LoopScroll,
    Scroll,
    CountDownBG,
    CountDown,
    ArrowComp,
    SlideComp,
    Marquee,
    ExpandHide,
    AccordionBanner,
    FlyImage,
    FlipImage,
    OpenDoorAlert,
    ScaleAlert,
    NestBanner,
    SingleNav,
    ChangeBtn,
    ShopInform,
}

export default plugins