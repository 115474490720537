import React, { useRef, useMemo, useEffect, useState, useImperativeHandle, useLayoutEffect } from 'react'
// import { rpx1x, handlePress } from '../../utils'
import _ from 'lodash'
// const flyImageId = 'tgb_fly-image_' + Date.now()

export default React.forwardRef(function Preview({
    rectOptions,
    props,
    zIndex = 0,
    floorData,
    currentIndex = 0,
    track = () => 0,
}, ref) {
    const { subRectOptions } = rectOptions
    const { subProps, flyImageList } = props
    const allFlyWrapList = useRef([])
    const wrapTimerGrp = useRef([])
    const isEnterAnimate = useRef(false)
    const hybridList = useMemo(() => {
        const currentFlyImg = flyImageList[currentIndex]
        return currentFlyImg ? subRectOptions.map((item, index) => {
            return {
                width: item.width,
                height: item.height,
                top: item.top,
                left: item.left,
                flyDistance: currentFlyImg[`flyDistance${index + 1}`],
                flyDirection: currentFlyImg[`flyDirection${index + 1}`],
                flyTime: currentFlyImg[`flyTime${index + 1}`],
                imageUrl: currentFlyImg[`imageUrl${index + 1}`],
                hotArea: currentFlyImg[`hotArea${index + 1}`]
            }
        }) : []
    }, [currentIndex])

    const flyImageId = useMemo(() => {
        return 'tgb_fly-image_' + Date.now()
    }, [currentIndex])


    useLayoutEffect(() => {
        if (hybridList.length <= 0) return
        allFlyWrapList.current = []
        wrapTimerGrp.current = []
        hybridList.forEach((item, index) => {
            allFlyWrapList.current.push(document.getElementById(`${flyImageId + '-' + index}`))
            wrapTimerGrp.current.push(null)
        })
        outView()
        setTimeout(() => {
            inView()
        }, 100)

    }, [currentIndex])


    const initAnimateStyle = (hybridItem) => {
        let style = {}
        switch (hybridItem.flyDirection) {
            case '左':
                style = {
                    transform: `translateX(-${hybridItem.flyDistance}px)`,
                }
                break
            case '右':
                style = {
                    transform: `translateX(${hybridItem.flyDistance}px)`
                }
                break
            case '上':
                style = {
                    transform: `translateY(-${hybridItem.flyDistance}px)`
                }
                break
            case '下':
                style = {
                    transform: `translateY(${hybridItem.flyDistance}px)`
                }
                break
        }
        return style
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         inView()
    //     }, 100)
    // }, [])

    useImperativeHandle(ref, () => ({
        inView,
        outView,
    }))

    const inView = () => {
        allFlyWrapList.current.forEach((elem, index) => {
            const item = hybridList[index]
            elem.style.transition = `transform ${item.flyTime}s`
            elem.style.transform = 'translate(0px, 0px)'
        })
    }

    const outView = () => {
        isEnterAnimate.current = false
        allFlyWrapList.current.forEach((elem, index) => {
            const item = hybridList[index]
            elem.style.transition = 'transform 0ms'
            elem.style.transform = initAnimateStyle(item).transform
        })
        wrapTimerGrp.current.forEach(timer => clearInterval(timer))
    }

    return (
        <>
            {
                hybridList.length > 0 && hybridList.map((item, index) => {
                    return (
                        <div
                            key={index}
                            id={flyImageId + '-' + index}
                            style={Object.assign({
                                width: item.width,
                                height: item.height,
                                position: 'absolute',
                                left: item.left,
                                top: item.top,
                                zIndex,
                            }, initAnimateStyle(item))}
                            onClick={() => {
                                window.open(itme.clickUrl)
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    transition: `${item.animateRate}s`
                                }}
                            >
                                <img style={{
                                    width: '100%',
                                    height: '100%',
                                }} src={item.imageUrl} alt="" />
                            </div>

                        </div>
                    )

                })
            }
        </>
    )
})