import Comp from "./comp";
import Desc from "./desc";
import Preview from "./preview";

const defaultRectOptions = {
  width: 375,
  height: 667,
  left: 0,
  top: 0,
  rotate: 0,
  subRectOptions: [],
};

const defaultDrawOptions = {
  resize: true,
  drag: true,
  rotate: false,
  keepRatio: false,
  renderDirections: ["nw", "n", "ne", "w", "e", "sw", "s", "se"],
  subOptions: [],
};

const plugin = {
  name: "上层飞入图",
  type: "TopBannerFly-image",
  render: () => <></>,
  board: Desc,
  preview: Preview,
  subPlugins: [Comp, Comp, Comp, Comp],
  props: {
    flyImageList: [
      {
        imageUrl1:
          "https://th.bing.com/th?id=OIP.Cdq25dINGG8gky7W0x8XaQHaE7&w=306&h=204&c=8&rs=1&qlt=90&o=6&dpr=2&pid=3.1&rm=2",
        clickUrl1: "",
        flyDirection1: "左",
        flyDistance1: 300,
        flyTime1: 2,
        hotArea1: [],
        imageUrl2:
          "https://th.bing.com/th?id=OIP.6szqS1IlGtWsaiHQUtUOVwHaQC&w=161&h=349&c=8&rs=1&qlt=90&o=6&dpr=2&pid=3.1&rm=2",
        clickUrl2: "",
        flyDirection2: "右",
        flyDistance2: 300,
        flyTime2: 2,
        hotArea2: [],
        imageUrl3:
          "https://th.bing.com/th?id=OIP.6szqS1IlGtWsaiHQUtUOVwHaQC&w=161&h=349&c=8&rs=1&qlt=90&o=6&dpr=2&pid=3.1&rm=2",
        clickUrl3: "",
        flyDirection3: "右",
        flyDistance3: 300,
        flyTime3: 2,
        hotArea3: [],
        imageUrl4:
          "https://th.bing.com/th?id=OIP.6szqS1IlGtWsaiHQUtUOVwHaQC&w=161&h=349&c=8&rs=1&qlt=90&o=6&dpr=2&pid=3.1&rm=2",
        clickUrl4: "",
        flyDirection4: "右",
        flyDistance4: 300,
        flyTime4: 2,
        hotArea4: [],
      },
    ],
    subProps: [],
  },
  extension: {
    struct: {
      imageUrl1:
        "https://th.bing.com/th?id=OIP.Cdq25dINGG8gky7W0x8XaQHaE7&w=306&h=204&c=8&rs=1&qlt=90&o=6&dpr=2&pid=3.1&rm=2",
      clickUrl1: "",
      flyDirection1: "左",
      flyDistance1: 300,
      flyTime1: 2,
      imageUrl2:
        "https://th.bing.com/th?id=OIP.6szqS1IlGtWsaiHQUtUOVwHaQC&w=161&h=349&c=8&rs=1&qlt=90&o=6&dpr=2&pid=3.1&rm=2",
      clickUrl2: "",
      flyDirection2: "右",
      flyDistance2: 300,
      flyTime2: 2,
      imageUrl3:
      "https://th.bing.com/th?id=OIP.6szqS1IlGtWsaiHQUtUOVwHaQC&w=161&h=349&c=8&rs=1&qlt=90&o=6&dpr=2&pid=3.1&rm=2",
      clickUrl3: "",
      flyDirection3: "右",
      flyDistance3: 300,
      flyTime3: 3,
      imageUrl4:
      "https://th.bing.com/th?id=OIP.6szqS1IlGtWsaiHQUtUOVwHaQC&w=161&h=349&c=8&rs=1&qlt=90&o=6&dpr=2&pid=3.1&rm=2",
      clickUrl4: "",
      flyDirection4: "右",
      flyDistance4: 300,
      flyTime4: 3,
    },
    flyDistanceRange: [0, 500],
    flyTimeRange: [0, 10],
  },
  rectOptions: Object.assign(defaultRectOptions, {
    subRectOptions: [
      {
        width: 375,
        height: 400,
        top: 16,
        left: 8,
      },
      {
        width: 200,
        height: 100,
        top: 160,
        left: 100,
      },
      {
        width: 200,
        height: 100,
        top: 160,
        left: 100,
      },
      {
        width: 200,
        height: 100,
        top: 160,
        left: 100,
      },
    ],
  }),
  drawOptions: Object.assign(defaultDrawOptions, {
    subOptions: [
      {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
      },
      {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
      },
      {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
      },
      {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
      },
    ],
  }),
};

export default plugin;
