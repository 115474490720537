import Comp from './comp'
import Desc from './desc'
import Preview from './preview'

const defaultRectOptions = {
    width: 375,
    height: 667,
    left: 0,
    top: 0,
    rotate: 0,
    subRectOptions: []
}

const defaultDrawOptions = {
    resize: true,
    drag: true,
    rotate: false,
    keepRatio: true,
    renderDirections: ["nw","n","ne","w","e","sw","s","se"],
    subOptions: [],
}
const plugin = {
    name: '飞入图',
    type: 'fly-image',
    render: () => <></>,
    board: Desc,
    preview: Preview,
    subPlugins: [Comp, Comp, Comp, Comp],
    props: {
        subProps: [
            {
                imageUrl: '//img30.360buyimg.com/zx/jfs/t1/121697/12/17116/402477/5fa13848Eebff5399/78274fd96a731399.jpg',
                "clickUrl": "",
                "flyDirection": "左",
                flyDistance: 300,
                flyTime: 2,
                animateType: '放大缩小',
                scrollDistance: 30,
                scale: 0.8,
                animateRate: 3,
                show: true,
            },
            {
                "imageUrl": "https://th.bing.com/th?id=OIP.6szqS1IlGtWsaiHQUtUOVwHaQC&w=161&h=349&c=8&rs=1&qlt=90&o=6&dpr=2&pid=3.1&rm=2",
                "clickUrl": "",
                "flyDirection": "右",
                flyDistance: 300,
                flyTime: 2,
                animateType: '放大缩小',
                animateScope: 20,
                animateRate: 3,
                scrollDistance: 30,
                scale: 0.8,
                show: true,
            },
            {
                "imageUrl": "https://th.bing.com/th?id=OIP.6szqS1IlGtWsaiHQUtUOVwHaQC&w=161&h=349&c=8&rs=1&qlt=90&o=6&dpr=2&pid=3.1&rm=2",
                "clickUrl": "",
                "flyDirection": "右",
                flyDistance: 300,
                flyTime: 2,
                animateType: '放大缩小',
                animateScope: 20,
                animateRate: 3,
                scrollDistance: 30,
                scale: 0.8,
                show: true,
            },
            {
                "imageUrl": "https://th.bing.com/th?id=OIP.6szqS1IlGtWsaiHQUtUOVwHaQC&w=161&h=349&c=8&rs=1&qlt=90&o=6&dpr=2&pid=3.1&rm=2",
                "clickUrl": "",
                "flyDirection": "右",
                flyDistance: 300,
                flyTime: 2,
                animateType: '放大缩小',
                animateScope: 20,
                animateRate: 3,
                scrollDistance: 30,
                scale: 0.8,
                show: true,
            }
        ],
    },
    extension: {
        subLen: 4,
        flyDistanceRange: [0, 500],
        flyTimeRange: [0, 10],
        animateScopeRange: [0, 90],
        animateRateRange: [0, 10],
        scrollDistanceRange: [0, 375],
        animateTypeRadioList: [
            "左右滑动",
            "上下滑动",
            "放大缩小"
        ],
        flyDirectionRadioList: [
            "左",
            "右",
            "上",
            "下"
        ]
    },
    rectOptions: Object.assign(defaultRectOptions, {
        subRectOptions: [
            {
                "width": 100,
                "height": 100,
                "top": 160,
                "left": -90,
            },
            {
                "width": 200,
                "height": 100,
                "top": 160,
                "left": 100,
            },
            {
                "width": 150,
                "height": 100,
                "top": 200,
                "left": 50,
            },
            {
                "width": 200,
                "height": 100,
                "top": 300,
                "left": 10,
            }
        ]
    }),
    drawOptions: Object.assign(defaultDrawOptions, {
        subOptions: [
            {
                resize: true,
                drag: true,
                rotate: false,
                keepRatio: true,
            },
            {
                resize: true,
                drag: true,
                rotate: false,
                keepRatio: true,
            },
            {
                resize: true,
                drag: true,
                rotate: false,
                keepRatio: true,
            },
            {
                resize: true,
                drag: true,
                rotate: false,
                keepRatio: true,
            },
        ]
        
    })
}

export default plugin